/** @jsx jsx */
import React, { useState, useEffect } from "react"
import query from "query-string"
import { navigate } from "gatsby"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { Col, Row } from "boostly-ui2"

import api from "../api"

import Input from "../components/Input"
import MainButton from "../components/MainButton"
import Layout from "../components/layout/Layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Title1 = styled.h1`
  margin: 0px;
  font-size: 28px;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  color: #17174e;
`
const Title2 = styled.h2`
  margin: 8px 0px 0px 0px;
  font-size: 20px;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  color: #343450;
`
const Title3 = styled.h3`
  margin: 0px;
  font-size: 16px;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  color: #50507c;
`
const Disclaimer = styled.div`
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 13px;
  text-align: center;
  color: hsl(0, 0%, 40%);
  font-family: PT sans;
`
const selectStyle = css`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #7f7f7f;
  color: hsl(0, 0%, 60%);
  font-size: 18px;
  font-weight: 600;
  font-family: sans-serif;
  text-align: center;
  padding-bottom: 6px;
`
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

// Text Formatting Rules
const telephoneRegex = /(\d{0,3})(\d{0,3})(\d{0,4})/
const telephoneFormatter = text => {
  let x = text.replace(/\D/g, "").match(telephoneRegex)
  return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
}

const daysForMonth = month => {
  if ([1, 3, 5, 7, 8, 11, 12].includes(month)) {
    return 31
  } else if (month === 2) {
    return 29
  } else {
    return 30
  }
}

const IndexPage = props => {
  const [unit, setUnit] = useState({})
  const [promo, setPromo] = useState({})
  const [isLoadingPromo, setIsLoadingPromo] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [campaignId, setCampaignId] = useState({})
  const [phoneNumber, setNumber] = useState("")
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [daysAvail, setDaysAvail] = useState(31)
  const [selectedMonth, setMonth] = useState(0)
  const [selectedDay, setDay] = useState(0)
  const [isPhoneValid, setPhoneValid] = useState(false)
  const handleSubmit = async e => {
    e.preventDefault()
    const phoneValid = phoneNumber.replace(/\D/g,'').length === 10
    if (phoneValid) {
      const potential = {
        phone: phoneNumber,
        firstName: firstName,
        lastName: lastName,
        birthdayMonth: selectedMonth,
        birthdayDay: selectedDay,
      }
      setIsSubmitting(true)
      api
        .submit({
          campaignId,
          potential,
        })
        .then(({ data }) => {
          navigate(`/confirm?result=${data.result}`)
          setIsSubmitting(false)
        })
        .catch(err => {
          //TODO: Setup a better situation for failed subs
          navigate(`/confirm`)
          setIsSubmitting(false)
        })
    }
    setPhoneValid(phoneValid)
  }

  useEffect(() => {
    const { campaignId } = query.parse(props.location.search)
    setCampaignId(campaignId)
    setIsLoadingPromo(true)
    api
      .load({ campaignId })
      .then(({ data, ...rest }) => {
        setPromo(data.promo)
        setUnit(data.unit)
        setIsLoadingPromo(false)
      })
      .catch(console.log)
  }, [])

  useEffect(() => {
    setDaysAvail(daysForMonth(selectedMonth))
    if (selectedDay > daysAvail) setDay(0)
  }, [selectedMonth])

  const handleMonthChange = e => {
    setMonth(parseInt(e.target.value))
  }
  const handleDayChange = e => {
    setDay(parseInt(e.target.value))
  }

  return (
    <Layout bgShape={require(`../images/MuralWave.svg`)}>
      <Title1>
        Join {unit.name || "Loading..."} Mobile Club & Get{" "}
        {isLoadingPromo ? "Loading..." : promo.name}
      </Title1>
      {/* <form action="submit" onSubmit={handleSubmit} css={css`width: 100%;`}> */}
      <Col mt={`16px`} w={`100%`}>
        <form onSubmit={handleSubmit}>
          <Title2>Mobile Number*</Title2>
          <Title3>Where you will receive sweet deals</Title3>
          <Input
            placeholder="(123) 100-2000"
            formatter={telephoneFormatter}
            onChange={setNumber}
            error={!isPhoneValid}
            required
          />
          <Title2>Name</Title2>
          <Title3>You have one, right?</Title3>
          <Row space="between">
            <Input placeholder="Jane" onChange={setFirstName} />
            <Input
              placeholder="Doe"
              onChange={setLastName}
              css={css`
                margin-left: 20px;
              `}
            />
          </Row>
          <Title2>Birthday</Title2>
          <Title3>If you like surprises...</Title3>
          <Row mt={`8px`}>
            <select
              id="monthPicker"
              name="monthPicker"
              onChange={handleMonthChange}
              css={css`
                ${selectStyle};
                ${selectedMonth && "color: black; font-weight: normal;"}
              `}
              defaultValue="DEFAULT"
            >
              <option value="DEFAULT" disabled>
                Month
              </option>
              {months.map((month, i) => (
                <option value={i + 1} key={i}>
                  {month}
                </option>
              ))}
            </select>
            <select
              id="dayPicker"
              name="dayPicker"
              onChange={handleDayChange}
              css={css`
                ${selectStyle};
                margin-left: 20px;
                ${selectedDay && "color: black; font-weight: normal;"}
              `}
              defaultValue="DEFAULT"
            >
              <option value="DEFAULT" disabled>
                Day
              </option>
              {[...Array(daysAvail).keys()].map((day, i) => (
                <option value={day + 1} key={i}>
                  {day + 1}
                </option>
              ))}
            </select>
          </Row>
          <MainButton
            css={css`
              width: 100%;
            `}
            h="44px"
            w="80%"
            mt="20px"
            color="#ea535d"
            type="submit"
            isLoading={isSubmitting}
          >
            Subscribe
          </MainButton>
        </form>
        {/* <CenteredAniLink paintDrip to={`/confirm`} hex="#E74953" duration={0.5}>
          <MainButton h="44px" w="80%" mt="20px" color="#ea535d">
            Subscribe
          </MainButton>
        </CenteredAniLink> */}
        <Disclaimer>
          By clicking, I agree to recieve automated marketing messages from
          Boostly at the number provided. Consent is not required to purchase
          goods or services. Msg & Data rates may apply. Msg frequency varies.
          <a
            href="https://bstly.us"
            css={css`
              display: block;
              color: inherit;
              font-family: inherit;
            `}
          >
            Terms & Conditions / Privacy Policy
          </a>
        </Disclaimer>
      </Col>
    </Layout>
  )
}

export default IndexPage
