/**@jsx jsx */
import React from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"

const InputField = styled.input`
  margin-top: 4px;
  outline: 0;
  border-width: 0 0 2px;
  height: 30px;
  text-align: left;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
  ::placeholder {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: hsl(0, 0%, 60%);
    font-family: sans-serif;
  }
  &:focus {
    border-color: rebeccapurple;
  }
`
const Input = ({ placeholder, formatter, onChange, className, error, ...props }) => {
  const handleTextInput = e => {
    formatter && (e.target.value = formatter(e.target.value))
    onChange(e.target.value)
  }

  return (
    <InputField
      css={css`border-color: ${error ? "red" : "#7f7f7f"}; }`}
      className={className}
      type="text"
      placeholder={placeholder}
      onChange={handleTextInput}
      {...props}
    />
  )
}

export default Input
