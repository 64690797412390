import axios from "axios"

const apiRoot = process.env.API_ROOT
const baseURL = `${apiRoot}/api/subs`

export const load = data => axios.post(`${baseURL}/load-campaign`, data)
export const submit = data => axios.post(`${baseURL}/new`, data)

export default {
  load,
  submit,
}
