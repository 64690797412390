/** @jsx jsx */
import React, { useState } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import Spinner from "react-spinkit"

const disabledStyle = css`
  filter: brightness(80%);
`

const MainButton = ({
  mt = "5px",
  w = "auto",
  minW = "none",
  h = "auto",
  color = "tomato",
  isLoading,
  className,
  ...props
}) => {
  const Button = styled.button`
    height: ${h};
    width: ${w};
    margin-top: ${mt};
    min-width: ${minW};
    background-color: ${color};
    border: none;
    padding: 3px 0px;
    border-radius: 999px;
    color: white;
    font-size: 30px;
    font-family: righteous;
    letter-spacing: 2px;
    box-sizing: border-box;
    text-align: center;
    transition: 0.5s;
    box-shadow: 0 4px 10px rgba(50, 50, 93, 0.11);
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.2),
        0 3px 6px rgba(0, 0, 0, 0.08);
      filter: brightness(110%);
    }
  `
  return (
    <>
      <Button
        className={className}
        {...props}
        disabled={isLoading}
        css={isLoading && disabledStyle}
      >
        {props.children}
        {isLoading && <Spinner name="three-bounce" />}
      </Button>
    </>
  )
}

export default MainButton
